import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

interface Post {
  provider: string;
  image: string;
  text: string;
  link: string;
}

interface SocialMediaPostProps {
  post: Post;
  twitter: any;
  instagram: any;
  facebook: any;
}

const SocialMediaPost = (props: SocialMediaPostProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const overlayClasses = classnames({
    'bg-black h-full w-full text-sm p-4 font-semibold text-white flex flex-col justify-between sans-serif': true,
    'bg-blue-800': props.post.provider === 'facebook',
    'bg-orange-900': props.post.provider === 'instagram',
    'bg-opacity-70': isHovered,
    'bg-opacity-0': !isHovered
  });

  const textClasses = classnames({
    hidden: !isHovered && props.post.provider === 'instagram',
    block: isHovered || props.post.provider === 'twitter'
  });

  const socialHandleClasses = classnames({
    'text-blue-100 font-bold ml-2 text-md': true,
    hidden: !isHovered && props.post.provider !== 'twitter',
    block: isHovered || props.post.provider === 'twitter'
  });

  return (
    <div
      className="w-full md:w-1/4 lg:w-1/5 h-32 sm:h-64 bg-cover bg-center cursor-pointer overflow-hidden"
      style={{ backgroundImage: `url(${props.post.image})` }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a href={props.post.link} target="_blank">
        <div className={overlayClasses}>
          <p className={textClasses}>{props.post.text}</p>
          <div className="flex">
            <Img
              className="w-6"
              fluid={
                props.post.provider === 'instagram'
                  ? props.instagram?.childImageSharp.sizes
                  : ''
              }
            />
            <p className={socialHandleClasses}>
              {props.post.provider === 'twitter' ? (
                <span>@TCRWP</span>
              ) : props.post.provider === 'instagram' ? (
                <span>@tcrwp</span>
              ) : props.post.provider === 'facebook' ? (
                <span>@readingandwritingproject</span>
              ) : (
                <span>@tcrwp</span>
              )}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

const SocialMedia: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const getSocialFeed = async () => {
      try {
        const req = await fetch('/.netlify/functions/socialFeed');
        const data = await req.json();
        const preparedPosts: Post[] = data.splice(0, 10).map((post: any) => ({
          provider: 'instagram',
          image: post.thumbnail_url ?? post.media_url,
          link: post.permalink,
          text: post.caption
        }));
        setPosts(preparedPosts);
      } catch (err) {
        console.log('error getting social feed', err);
      }
    };

    getSocialFeed();
  }, []);

  const data = useStaticQuery(graphql`
    query {
      twitter: file(relativePath: { eq: "images/twitter.png" }) {
        childImageSharp {
          sizes(maxWidth: 50) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      instagram: file(relativePath: { eq: "images/instagram.png" }) {
        childImageSharp {
          sizes(maxWidth: 50) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      facebook: file(relativePath: { eq: "images/facebook.png" }) {
        childImageSharp {
          sizes(maxWidth: 50) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);

  return (
    <div className="bg-primary flex flex-col sm:flex-row sm:flex-wrap mt-10">
      {posts.map((p, i) => (
        <SocialMediaPost
          post={p}
          twitter={data.twitter}
          instagram={data.instagram}
          facebook={data.facebook}
          key={i}
        />
      ))}
    </div>
  );
};

export default SocialMedia;
