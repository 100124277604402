import React from 'react';
import Card from './common/Card';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

interface ServiceCardProps {
  title: string;
  body: string;
  image: any;
  link: string;
  linkLabel: string;
}

const ServiceCard: React.FC<ServiceCardProps> = props => {
  return (
    <div className="">
      <div className="flex flex-col m-auto">
        <Img
          className="rounded-lg w-full mb-6 shadow-xl h-full"
          fluid={props.image.childImageSharp.sizes}
        />
        <h3 className="text-primary font-bold text-2xl mb-2 text-center">
          {props.title}
        </h3>
        <div
          className="text-gray-500 text-lg mt-4 self-center"
          dangerouslySetInnerHTML={{ __html: props.body }}
        />
      </div>
      <div className="flex flex-col justify-end my-auto mt-2 lg:mt-8">
        <Link to={props.link} className="text-primary font-bold text-lg">
          View {props.linkLabel} &rarr;
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
