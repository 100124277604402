import React, { useCallback, useState } from 'react';
import LoadingButton from './common/LoadingButton';
import { Formik, Form, Field } from 'formik';

interface MailingListFieldInterface {
  label: string;
  placeholder: string;
  name: string;
}

const MailingListField = (props: MailingListFieldInterface) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={props.name}
        className="ml-4 font-bold text-light_green sans-serif sm:text-xl"
      >
        {props.label}
      </label>
      <Field
        className="px-5 py-3 my-1 text-lg text-gray-700 rounded-full bg-primary_light focus:outline-none sm:mb-0 placeholder:text-gray-500"
        placeholder={props.placeholder}
        id={props.name}
        name={props.name}
      />
    </div>
  );
};

const MailingListSignup: React.FC = () => {
  const [submitState, setSubmitState] = useState('IDLE');
  const submitForm = useCallback(async values => {
    try {
      setSubmitState('SUBMITTING');
      const response = await fetch(
        `${process.env.GATSBY_FUNCTIONS_PATH}/mailingListSignup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...values
          })
        }
      );

      if (!response.ok) {
        setSubmitState('SUBMIT_ERROR');
        return;
      }

      const data = await response.json();

      if (data.Code) {
        setSubmitState('SUBMIT_ERROR');
      } else {
        setSubmitState('SUBMIT_SUCCESS');
      }
    } catch (err) {
      setSubmitState('SUBMIT_ERROR');
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center pt-10 pb-6 rounded-lg sm:px-10">
      <h3
        id="mailing-list"
        className="text-3xl font-bold text-center text-white"
      >
        Join the Mailing List
      </h3>
      <p className="mt-6 text-xl text-center text-white">
        Sign up to join our community of readers and writers
      </p>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          school: '',
          stateProvince: '',
          country: ''
        }}
        onSubmit={values => {
          submitForm(values);
        }}
      >
        <Form className="flex flex-col items-center justify-center w-full m-auto mt-10 xl:w-3/4 gap-y-3">
          <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
            <div className="w-full lg:w-1/3">
              <MailingListField
                label="First Name"
                placeholder="John"
                name="firstName"
              />
            </div>
            <div className="w-full lg:w-1/3">
              <MailingListField
                label="Last Name"
                placeholder="Doe"
                name="lastName"
              />
            </div>
            <div className="w-full lg:w-1/3">
              <MailingListField
                label="Email"
                placeholder="johndoe@email.com"
                name="email"
              />
            </div>
          </div>
          <div className="mx-auto mt-3 mb-1">
            <LoadingButton
              type="submit"
              text="Submit"
              size="md"
              color="dark"
              isLoading={submitState === 'SUBMITTING'}
            />
          </div>
        </Form>
      </Formik>
      <div className="w-1/2 m-auto mt-6">
        {submitState === 'SUBMIT_SUCCESS' && (
          <div className="p-4 bg-green-300 border border-green-700 rounded-lg">
            <p className="text-center text-green-700">
              Thanks for joining our mailing list! You've been successfully
              added.
            </p>
          </div>
        )}
        {submitState === 'SUBMIT_ERROR' && (
          <div className="p-4 bg-red-300 border border-red-700 rounded-lg">
            <p className="text-center text-red-700">
              There was a problem adding you to the mailing list. Please try
              again.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MailingListSignup;
