import React from 'react';
import {
  FaFacebook,
  FaInstagramSquare,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';

const SocialLinks = () => {
  return (
    <div className="flex mx-auto text-5xl text-primary justify-items-center">
      <div className="mr-8">
        <a
          href="https://www.instagram.com/thereadingandwritingproject/"
          target="_blank"
        >
          <span className="sr-only">Instagram</span>
          <FaInstagramSquare />
        </a>
      </div>
      <div className="mr-8">
        <a
          href="https://www.facebook.com/profile.php?id=61550480949113"
          target="_blank"
        >
          <span className="sr-only">Facebook</span>
          <FaFacebook />
        </a>
      </div>
      <div className="mr-8">
        <a href="https://twitter.com/mossflowerrwp" target="_blank">
          <span className="sr-only">Twitter</span>
          <FaTwitter />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
