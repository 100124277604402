import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import BlogPostsSection from '../components/BlogPostsSection';
import Spacer from '../components/common/Spacer';
import FacebookGroups from '../components/FacebookGroups';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import MailingListSignup from '../components/MailingListSignup';
import NewsSlider from '../components/NewsSlider';
import Resources from '../components/Resources';
import SectionHeader from '../components/SectionHeader';
import SEO from '../components/Seo';
import Services from '../components/Services';
import SocialLinks from '../components/SocialLinks';
import SocialMedia from '../components/SocialMedia';
import UpcomingEvents from '../components/UpcomingEvents';
import { extractContent } from './../util';
config.autoAddCss = false;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEvent(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: startDate, order: ASC }
      ) {
        edges {
          node {
            id
            title
            startDate
            endDate
            description {
              description
            }
            slug
            price
            image {
              sizes(maxWidth: 600) {
                base64
                srcWebp
                srcSetWebp
              }
            }
          }
        }
      }
      allContentfulResource(
        limit: 12
        filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
        sort: { fields: homepageOrder, order: ASC }
      ) {
        edges {
          node {
            title
            body {
              body
            }
            image {
              fluid {
                src
              }
            }
            type
            slug
            featuredResources {
              title
            }
          }
        }
      }
      allContentfulNewsItem(
        filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            slug
          }
        }
      }
      blogPostsForSlider: allContentfulBlogPost(
        filter: { showOnHomepage: { eq: true }, node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            slug
            featuredImage {
              sizes {
                src
              }
            }
          }
        }
      }
      allContentfulBlogPost(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: publishedDate, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            featuredImage {
              fluid {
                src
              }
            }
            title
            publishedDate
            createdAt
            slug
            author {
              firstName
              lastName
              image {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
      blogFallbackImage: file(
        relativePath: { eq: "images/news-fallback.png" }
      ) {
        childImageSharp {
          sizes(maxWidth: 300) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card1Image: file(relativePath: { eq: "images/info-card-1-2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card2Image: file(relativePath: { eq: "images/info-card-2-2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      card3Image: file(relativePath: { eq: "images/info-card-3-3.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      facebookGroupsImage: file(
        relativePath: { eq: "images/info-card-2.jpg" }
      ) {
        childImageSharp {
          sizes(maxWidth: 300) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      bgImage: file(relativePath: { eq: "images/teachers-college-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jumpRopeReaders: file(
        relativePath: { eq: "images/jump-rope-readers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const events = data.allContentfulEvent.edges
    .map((e: any) => e.node)
    .filter((e: any) => new Date(e.endDate) >= new Date());

  const blogPostsForSlider = extractContent(data.blogPostsForSlider);
  const newsItems = extractContent(data.allContentfulNewsItem);
  const blogPosts = extractContent(data.allContentfulBlogPost);
  const resources = extractContent(data.allContentfulResource);

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Spacer x={10} y={20}>
        <div className="mt-8 lg:grid-cols-3 lg:mt-0">
          <Services data={data} />
        </div>
      </Spacer>
      <div>
        <Spacer x={10} y={20}>
          <div className="flex mx-auto">
            <SectionHeader
              superheader=""
              header="Connect with Us on Social Media"
              classNames="mx-auto"
            />
          </div>
          <div className="flex my-4">
            <SocialLinks />
          </div>
          <SocialMedia />
        </Spacer>
        <div className="bg-primary-dark">
          <div className="px-20">
            <MailingListSignup />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
