import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const Hero: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "images/info-card-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const backgroundPosition = 'top center';

  return (
    <>
      <BackgroundImage
        fluid={data.heroImage.childImageSharp.fluid}
        style={{ backgroundPosition }}
      >
        <div className="flex items-center content-center w-full px-4 sm:px-20 from-white to-transparent bg-gradient-to-r">
          <div className="flex">
            <div className="mt-28 sm:w-7/12">
              <h1 className="mt-8 mb-8 text-2xl font-bold sm:mt-12 xl:mt-40 h-44 sm:h-56 lg:text-4xl sm:text-left lg:leading-snug text-primary-dark">
                Dedicated to the mission of helping all children grow to be
                enthusiastic and proficient readers and writers.
              </h1>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </>
  );
};

export default Hero;
