import React from 'react';
import ServiceCard from './ServiceCard';

interface ServicesProps {
  data: any;
}

const Services: React.FC<ServicesProps> = props => {
  return (
    <div className="grid sm:grid-cols-3 grid-cols-1 gap-10">
      <div className="">
        <ServiceCard
          title="Daypack Decodables"
          body={`<p>We are excited to introduce Daypack Decodables, a decodable series designed to support young readers as they progress through the stages of phonics development and learn that reading is about making meaning. This series, comprising 120 brief texts, has been designed to get texts in kids’ hands–in their book baggies, backpacks, and lunch boxes.</p>`}
          image={props.data.card1Image}
          link="/resources/daypack-decodable-series"
          linkLabel="Decodables"
        />
      </div>
      <div className="">
        <ServiceCard
          title="Quick Start Conference Days"
          body={`<p>These one-day conferences are tailored to meet the needs of each participating school. Sometimes the day helps teachers and leaders get started working within the Units of Study in Reading (K-2, 3-5, 6-8) Writing (K-2, 3-5, 6-8), or Phonics (K-2) and other times they bring participants up to date with state-of-the-art methods and resources. Either way, these days kindle a school’s energy for best practices in literacy instruction.</p>`}
          image={props.data.card2Image}
          link="/services"
          linkLabel="Services"
        />
      </div>
      <div className="">
        <ServiceCard
          title="Events"
          body={`<p>We host events virtually and in person across the world. Join us to study methods and plan curricula, revitalize our thinking, and most importantly, encourage our students to lead meaningful and active literate lives. Events feature celebrated authors, world-renowned teacher educators, and other leaders in the field of literacy and learning.</p>`}
          image={props.data.card3Image}
          link="/events"
          linkLabel="Events"
        />
      </div>
    </div>
  );
};

export default Services;
